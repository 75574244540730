<template>
  <b-modal :active.sync="isModalActive" has-modal-card>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Confirmare actiune</p>
      </header>
      <section class="modal-card-body">
        <p>
          Inregistrarea
          <b> {{ trashObjectName }} </b>va fi stearsa definitiv.
        </p>
        <p>Actiunea nu va putea fi anulata.</p>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="cancel">Anulare</button>
        <button class="button is-danger" @click="confirm">Confirmare</button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModalBox",
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    trashObjectName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isModalActive: false,
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
  watch: {
    isActive(newValue) {
      this.isModalActive = newValue;
    },
    isModalActive(newValue) {
      if (!newValue) {
        this.cancel();
      }
    },
  },
};
</script>
