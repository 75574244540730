<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      <b-button class="button is-primary" @click="handleAddAnnouncement">
        Oferta noua
      </b-button>
    </hero-bar>
    <section class="section is-main-section">
      <card-component
        class="has-table has-mobile-sort-spaced"
        title="Inregistrari"
        icon="file-multiple"
      >
        <sale-offers-announcements-table
          :announcements="announcements"
          @handleDeleteAnnouncement="handleDeleteAnnouncement"
        />
      </card-component>
      <hr />
    </section>
  </div>
</template>

<script>
import Announcements from "@/services/announcements.service";
import SaleOffersAnnouncementsTable from "@/components/announcements/saleOffers/saleOffersAnnouncements-table";
import CardComponent from "@/components/CardComponent";
import TitleBar from "@/components/TitleBar";
import HeroBar from "@/components/HeroBar";
const _ = require("lodash");
export default {
  name: "SaleOffersAnnouncements",
  components: {
    HeroBar,
    TitleBar,
    CardComponent,
    SaleOffersAnnouncementsTable,
  },
  data() {
    return {
      announcements: [],
    };
  },
  async created() {
    const token = await this.$auth.getTokenSilently();
    this.getAllAnnouncements(token).then((response) => {
      this.announcements = response;
    });
  },
  methods: {
    async getAllAnnouncements(token) {
      const { handleGetSaleOffersAnnouncements } = Announcements;
      const response = await handleGetSaleOffersAnnouncements(token);
      const { data } = response;
      return data;
    },
    async handleAddAnnouncement() {
      this.$router.push({
        path: "/saleOffersAnnouncements/add",
      });
    },
    async handleDeleteAnnouncement(id) {
      const { handleDeleteAnnouncement } = Announcements;
      const response = await handleDeleteAnnouncement(id);
      const { data, status } = response;

      if (status == "200") {
        const array = this.announcements;
        const index = this.announcements.findIndex((prop) => prop.id === id);
        this.announcements.splice(index, 1);
      } else {
      }
    },
  },
  computed: {
    titleStack() {
      return ["Oferte de vanzare"];
    },
    heroRouterLinkTo() {
      return { name: "/announcements/add" };
    },
  },
};
</script>
